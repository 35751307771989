/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { website as websiteTheme } from './src/theme/website';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    // eslint-disable-next-line
    import(`intersection-observer`);
  }
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  return location.action !== 'POP';
};

export const wrapRootElement = ({ element }) => {
  return <ChakraProvider theme={websiteTheme}>{element}</ChakraProvider>;
};
