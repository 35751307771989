// nouveau theme pour chakra
import { extendTheme } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

// Thème par default : https://chakra-ui.com/docs/theming/theme

const fonts = {
  heading:
    'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  body: 'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji'
};

const colors = {
  brand: {
    50: '#fff7e7',
    100: '#fdefce',
    200: '#fde7b6',
    300: '#fcd34d',
    400: '#fbc218',
    500: '#fba818',
    600: '#e76f21',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
    950: '#39251a'
  },
  gray: {
    50: '#f8f9fa',
    100: '#f4f6f7',
    200: '#dde1e4',
    300: '#c6cccf',
    400: '#afb7bb',
    500: '#79878d',
    600: '#555e62',
    700: '#485054',
    800: '#2f3437',
    900: '#18191b'
  },
  success: {
    // Tailwindcss Lime
    50: '#f7fee7',
    100: '#ecfccb',
    200: '#d9f99d',
    300: '#bef264',
    400: '#a3e635',
    500: '#84cc16',
    600: '#65a30d',
    700: '#4d7c0f',
    800: '#3f6212',
    900: '#365314'
  },
  danger: {
    // Tailwindcss Lime
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d'
  },
  blackAlpha: {
    750: 'rgba(0, 0, 0, 0.7)'
  }
};

const rowTransitions = {
  update: keyframes`
  0%{
    background-color: white;
    opacity: 0.01;
    transform: translate(-40px, 0)
  }
  100% {
    opacity: 1;
    background-color: white;
    transform: translate(0, 0);
    transition: all 500ms ease-in;
  }
`,
  remove: keyframes`
  0%{
    opacity: 1;
    background-color: white;
    transform: translate(0, 0)
  }
  100% {
    opacity: 0.2;
    background-color: white;
    transform: translate(40px, 0);
    transition: all 500ms ease-in;
  }
`
};

export const website = extendTheme({
  fonts,
  colors: {
    ...colors,
    red: colors.danger,
    green: colors.success,
    orange: colors.brand
  },
  shadows: {
    outline: `0 0 0 3px ${colors.brand['400']}99`
  },
  styles: {
    global: props => ({
      ':root, html': {
        bg: 'white'
      },
      body: {
        fontFamily: 'body',
        color: props.colorMode === 'dark' ? 'gray.50' : 'gray.900',
        bg: 'inherit !important',
        lineHeight: 'base',
        fontSize: 'md'
      },
      'button, button:focus, input, input:focus': {
        outline: 'none'
      },
      '.data-grid-view-action-update': {
        animation: `${rowTransitions.update} 800ms normal forwards ease`
      },
      '.data-grid-view-action-remove': {
        animation: `${rowTransitions.remove} 800ms normal forwards ease`
      },
      h1: {
        fontWeight: 'bold'
      },
      h2: {
        fontWeight: 'bold'
      },
      h3: {
        fontWeight: 'bold'
      },
      h4: {
        fontWeight: 'bold',
        fontSize: 'xl'
      },
      strong: {
        fontWeight: 'semibold'
      }
    })
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        cursor: 'pointer'
      },
      defaultProps: {
        size: 'lg',
        variant: 'solid',
        colorScheme: 'brand'
      },
      variants: {
        solid: props => ({
          color: props.colorScheme === 'brand' && 'black',
          bg: props.colorScheme === 'brand' && 'brand.400',
          _hover: { bg: props.colorScheme === 'brand' && 'brand.400' },
          _active: { bg: props.colorScheme === 'brand' && 'brand.400' }
        }),
        outline: props => ({
          color: props.colorScheme === 'brand' && 'brand.400',
          bg: 'white',
          _hover: { bg: 'white' },
          _active: { bg: 'white' }
        })
      },
      sizes: {
        lg: {
          fontSize: 'md',
          letterSpacing: '1px',
          borderRadius: 'lg',
          px: 4
        }
      }
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'brand.400',
        customElement: {
          opacity: 0.6,
          pointerEvents: 'none',
          svg: {
            fill: 'currentColor'
          }
        }
      },
      variants: {
        outline: props => ({
          field: {
            bg: props.colorMode === 'light' ? 'gray.50' : 'blackAlpha.400',
            color:
              props.colorMode === 'light' ? 'blackAlpha.750' : 'whiteAlpha.800',
            borderColor:
              props.colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300',
            fontWeight: 'medium',
            _placeholder: {
              color:
                props.colorMode === 'light'
                  ? 'blackAlpha.500'
                  : 'whiteAlpha.500'
            }
          }
        })
      }
    },
    Select: {
      defaultProps: {
        focusBorderColor: 'brand.400'
      }
    },
    Tabs: {
      defaultProps: {
        colorScheme: 'brand',
        focusBorderColor: 'brand.400'
      },
      variants: {
        line: {
          tab: {
            bg: 'blackAlpha.50',
            _selected: {
              bg: 'white'
            }
          },
          tablist: {
            borderColor: 'blackAlpha.200'
          }
        }
      },
      sizes: {
        md: {
          tab: {
            fontSize: 'md'
          }
        },
        lg: {
          tab: {
            fontSize: 'lg'
          }
        }
      }
    }
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  }
});
